import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/osymcodes', config);
}

const get = async (code) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/osymcodes/' + code);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if (data.id) {
        return Api.put('/osymcodes/' + data.id, data);
    }
    return Api.post('/osymcodes', data);
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/osymcodes/' + id, formData);
}

const deleteOsym = async (code) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/osymcodes/' + code);
}

const getExamResult = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/osym/service/student-exam-result-excel-export', config);
}

const exportExcel = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    config.responseType = 'arraybuffer'
    return Api.get('/osymcodes/excel-export', config);
}

export default {
    getAll,
    get,
    store,
    update,
    deleteOsym,
    getExamResult,
    exportExcel
}
